module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-36127220-1","G-8BTRR33MWK"],"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"株式会社バンコム","short_name":"バンコム","start_url":"/","background_color":"#f7f0eb","theme_color":"#a2466c","display":"standalone","icon":"src/images/icon.png","icons":[{"src":"siteicons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"siteicons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"siteicons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"siteicons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"siteicons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"siteicons/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"siteicons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"siteicons/icon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d5a50c2d40e468ab9bc098c9ed715593"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://www.ban.com/wp20200526/graphql/","develop":{"hardCacheMediaFiles":true,"nodeUpdateInterval":5000,"hardCacheData":false},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Home","exclude":["/dev-404-page/","/404/","/404.html","/offline-plugin-app-shell-fallback/"],"crumbLabelUpdates":[{"pathname":"/book","crumbLabel":"Books"}],"trailingSlashes":true,"usePathPrefix":"/blog"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
